<template>
  <a-affix :offset-top="0" @change="change">
    <header
      class="mobile-header"
      :style="{ background: affixed ? '#fff !important' : 'transparent' }"
    >
      <div class="main-logo">
        <router-link to="/" tag="a">
          <img class="logo" style="" src="https://b.iyunmu.com/oss/bzp/e6d7c536c79bd583b8aa12bd7b297a21.png" alt="" />
        </router-link>
      </div>
      <div class="right-menu" @click="toggleHandler">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
    <a-drawer
      title=" "
      placement="right"
      :closable="false"
      :visible="show"
      :getContainer="false"
      :mask="false"
    >
      <a-collapse
        v-model="activeKey"
        expand-icon-position="right"
        accordion
        :bordered="false"
      >
        <a-collapse-panel
          key="1"
          header="首页"
          :showArrow="false"
          disabled
          @click.native="gotoPage('/mobile')"
        >
        </a-collapse-panel>
        <a-collapse-panel key="2" header="服务支持">
          <p class="child-txt" @click="gotoPage('/mobile/service', 'help')">帮助中心</p>
          <!-- <p class="child-txt" @click="gotoPage('/mobile/service', 'agreement')">
            服务协议
          </p>
          <p class="child-txt" @click="gotoPage('/mobile/service', 'policy')">隐私政策</p> -->
        </a-collapse-panel>
        <a-collapse-panel
          key="3"
          header="关于我们"
          :showArrow="false"
          disabled
          @click.native="gotoPage('/mobile/about')"
        >
        </a-collapse-panel>
      </a-collapse>
    </a-drawer>
  </a-affix>
</template>

<script>
export default {
  data() {
    return {
      affixed: false,
      show: false,
      activeKey: 0,
    };
  },

  methods: {
    change(affixed) {
      this.affixed = affixed;
    },
    toggleHandler() {
      const toggleEle = document.querySelector(".right-menu");
      toggleEle?.classList.toggle("active");
      this.$parent.$children.forEach((v) => {
        v.$data.showBackUp && (v.$data.showBackUp = false);
      });
      this.show = !this.show;
      this.$parent.$children.forEach(
        (v) => v.$data.hasOwnProperty("showBackUp") && (v.$data.showBackUp = !this.show)
      );
    },
    gotoPage(path, key) {
      this.toggleHandler();
      if (key) {
        this.$router
          .push({
            name: "MBService",
            params: {
              key,
            },
          })
          .catch((err) => {
            this.$bus.$emit("mobileServiceChange", key);
          });
      } else {
        this.$router
          .push({
            path,
          })
          .catch((err) => {
            return;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-drawer {
    margin-top: vm(72);
    .ant-drawer-content-wrapper {
      width: 100vw !important;
      box-shadow: none;
      .ant-drawer-wrapper-body {
        padding: 0 vm(24);
        .ant-drawer-header {
          display: none;
        }
        .ant-drawer-body {
          padding: 0;
          .ant-collapse-item {
            background: #ffffff;
            border-bottom: vm(1) solid #edf0f3;
            .ant-collapse-header {
              padding: vm(16) 0 !important;
              height: vm(56);
              text-align: left;
              font-weight: bold;
              font-size: vm(16);
              color: #1f2431;
            }
            .ant-collapse-content {
              .child-txt {
                padding: vm(16) 0;
                font-weight: bold;
                font-size: vm(14);
                line-height: vm(22);
                color: #1f2431;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-header {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: vm(72);
  padding: vm(16) vm(24);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  transition: all 0.4s;
  width: 100%;
  z-index: 300;
  filter: drop-shadow(0 vm(2) vm(8) rgba(21, 23, 31, 0.08));
  .main-logo {
    .logo {
      width: vm(158);
      height: vm(40);
    }
  }
  .right-menu {
    width: vm(40);
    height: vm(70);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    span {
      position: absolute;
      width: vm(22);
      height: vm(2);
      background-color: #1f2431;
      border-radius: vm(4);
      transition: all 0.5s;
      left: vm(15);
    }
    span:nth-child(1) {
      transform: translateY(vm(-8));
      width: vm(22);
    }
    span:nth-child(3) {
      transform: translateY(vm(8));
      width: vm(22);
    }
    &.active {
      span:nth-child(1) {
        transform: translateY(0) rotate(45deg);
        width: vm(22);
      }
      span:nth-child(2) {
        transform: translateX(vm(60));
      }
      span:nth-child(3) {
        transform: translateY(0) rotate(315deg);
        width: vm(22);
      }
    }
  }
}
</style>
