import router from "./router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({ showSpinner: false });


router.beforeEach((to, from, next) => {
  NProgress.start();
  let is_mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if (to.fullPath.indexOf('mobile') == -1 && (is_mobile)) {
    const url = `/mobile${to.fullPath}`
    // console.log("移动端端URL", url);
    next({ path: url })
  } else if (to.fullPath.indexOf('mobile') !== -1 && !is_mobile ) {
    let url = to.fullPath.replace('/mobile', '')
    // console.log("PC端URL", url);
    next({ path: url})
  } else {
    next()
  }
});

router.afterEach(() => {
  NProgress.done();
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});


