<template>
  <a-affix :offset-top="top" @change="change" ref="scrollDiv">
    <header id="index-header" :style="{background: affixed?'#fff !important':'transparent'}">
      <div class="flex-row inner-box">
        <div class="main-logo">
          <router-link to="/" tag="a">
            <h1 class="logo-box">
              <img class="logo" style="" src="https://b.iyunmu.com/oss/bzp/e6d7c536c79bd583b8aa12bd7b297a21.png" alt="" />
            </h1>
          </router-link>
        </div>
        <div class="header-links flex-row">
          <span class="header-link" :class="[current == 0 ? 'active' : '']"
            ><router-link to="/" tag="a">首页</router-link></span
          >
          <span class="header-link" :class="[current == 1 ? 'active' : '']"
            ><router-link to="/service" tag="a">服务支持</router-link></span
          >
          <span class="header-link" :class="[current == 2 ? 'active' : '']"
            ><router-link to="/about" tag="a">关于我们</router-link></span
          >
          <div class="op-section">
            <a-button type="primary" class="login" @click="goLogin">
              登录平台
            </a-button>
          </div>
        </div>
      </div>
    </header>
  </a-affix>
</template>

<script>
export default {
  data() {
    return {
      top: 0,
      affixed: false,
      current:0
    };
  },

  methods: {
    change(affixed) {
      this.affixed = affixed;
    },
    goLogin(){
      window.open('https://bzp.iyunmu.com/') 
    }
  },
  watch: {
    $route:{
      handler(route){
        switch (route.name) {
          case 'PCHome':
            this.current = 0
            break;
          case 'PCService':
            this.current = 1
            break;
          case 'PCAbout':
            this.current = 2
            break;
        }
      },
      immediate:true
    }
  },
};
</script>

<style lang="scss" scoped>
#index-header:hover {
    background: #fff !important
}
#index-header {
  background: transparent;
  display: flex;
  justify-content: center;
  height: 72px;
  padding: 16px 0px;
  box-sizing: border-box;
  min-width: 1200px;
  position: fixed;
  top: 0;
  transition: all 0.4s;
  width: 100%;
  z-index: 300;
  filter: drop-shadow(0px 2px 8px rgba(21, 23, 31, 0.08));
  .inner-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    .main-logo {
      .logo-box {
        width: 158px;
        display: inline-block;
        position: relative;
        img {
          width: 100%;
          position: relative;
          height: 100%;
        }
      }
    }
    .header-links{
      align-items: center;
      padding: 0px;
      gap: 96px;
      width: 544px;
      height: 40px;
      .header-link{
        height: 100%;
        display: flex;
        align-items: center;
        a{
          height: 100%;
           display: flex;
        align-items: center;
          color: #1F2431;
        }
        a:hover{
          color: #4DAEFE;
        }
        &.active{
          a{
            color: #117DFF;
          }
        }
        
      }
      
     
      .login{
          border-radius:8px;
          height:40px;
          padding: 8px 16px;
          box-sizing: border-box;
          width: 96px;
        }
    }
  }
}
</style>
