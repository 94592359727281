<template>
  <div class="big-box">
    <PCHeaderNav />
      <router-view />
    <PCFooterInfo />
  </div>
</template>

<script>
import PCHeaderNav from "@/components/PCHeaderNav.vue";
import PCFooterInfo from "@/components/PCFooterInfo.vue";

export default {
  components: {
    PCHeaderNav,
    PCFooterInfo,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.big-box {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  width: 100%;
}
</style>
