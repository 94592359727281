<template>
  <div class="toolbox">
    <div class="main flex-col">
      <div class="toolbox-item scan" style="height: 72px; border-radius: 8px 8px 0 0">
        <a-popover
          placement="leftTop"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <template slot="content">
            <div class="flex-row just-between">
              <div class="small-box flex-col">
                <h1 class="small-title">关注官方公众号</h1>
                <p class="small-text">提供贴心的服务</p>
                <img src="https://b.iyunmu.com/oss/bzp/b527d23f449933e2f79cdc196d486e4f.png" alt="" class="small-img" />
              </div>
              <div class="small-box flex-col">
                <h1 class="small-title">打开官方小程序</h1>
                <p class="small-text">微信或支付宝扫码</p>
                <img src="https://b.iyunmu.com/oss/bzp/347b299976bcabc6e51abe6a41d99cdc.png" alt="" class="small-img" />
              </div>
            </div>
          </template>
          <div class="flex-col-center">
            <img src="@/assets/imgs/toolbox-scan.png" alt="" />
            <p>扫码</p>
          </div>
        </a-popover>
      </div>
      <div class="toolbox-item telephone" style="height: 92px">
        <a-popover
          placement="left"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <template slot="content">
            <div class="tel-small-box flex-col-center">
              <p class="tel-name">人工服务热线</p>
              <p class="tel-num">400-996-3000</p>
            </div>
          </template>
          <div class="flex-col-center">
            <img src="@/assets/imgs/toolbox-telephone.png" alt="" />
            <p>电话咨询</p>
          </div>
        </a-popover>
      </div>
      <div
        class="toolbox-item"
        style="height: 92px; border-radius: 0 0 8px 8px"
        @click="show = true"
      >
        <div class="flex-col-center">
          <img src="@/assets/imgs/toolbox-message.png" alt="" />
          <p>留言咨询</p>
        </div>
      </div>
    </div>
    <a-back-top>
      <div class="custom-up flex-col-center">
        <img src="@/assets/imgs/return.png" alt="" />
      </div>
    </a-back-top>
    <!-- 咨询弹框 -->
    <a-modal
      v-model="show"
      title=""
      :maskClosable="false"
      :footer="null"
      @cancel="cancelModal"
      wrapClassName="modal"
    >
      <div class="modal-top">
        <h1>1对1咨询专属顾问</h1>
        <p>收到留言后24小时内会联系您</p>
      </div>
      <div class="modal-main">
        <a-form :form="form" hideRequiredMark>
          <a-form-item>
            <span slot="label">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</span>
            <a-input
              v-decorator="[
                'username',
                { rules: [{ required: true, message: '请输入您的姓名' }] },
              ]"
              placeholder="请输入您的姓名"
            />
          </a-form-item>
          <a-form-item label="手机号码">
            <a-input
              v-decorator="['phone', validateRulesObj.phone]"
              placeholder="请输入手机号码"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="相关产品">
            <a-select
              v-decorator="[
                'productType',
                { rules: [{ required: true, message: '请选择产品类型' }] },
              ]"
              placeholder="请选择产品类型"
              :dropdownStyle="{ padding: '0 8px' }"
            >
              <a-select-option
                :value="item.value"
                v-for="(item, index) in products"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="留言内容">
            <a-radio-group v-model="messageType">
              <a-radio :value="0"> 需求描述 </a-radio>
              <a-radio :value="1"> 产品建议 </a-radio>
              <a-radio :value="2"> 其他 </a-radio>
            </a-radio-group>
            <a-textarea
              placeholder="请输入..."
              :rows="5"
              style="resize: none; margin-top: 5px"
              v-decorator="[
                'context',
                {
                  rules: [
                    { required: true, message: '请输入留言内容' },
                    { min: 3, message: '留言内容应不少于3个词' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24, offset: 6 }">
            <a-button
              type="primary"
              @click="handleSubmit"
              block
              style="border-radius: 8px; height: 40px; margin-top: 10px"
              :loading="loading"
            >
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
const products = [
  {
    name: "电表",
    value: "电表",
  },
  {
    name: "水表",
    value: "水表",
  },
  {
    name: "子母表",
    value: "子母表",
  },
  {
    name: "采集器",
    value: "采集器",
  },
  {
    name: "问题反馈",
    value: "问题反馈",
  },
  {
    name: "其他",
    value: "其他",
  },
];

import axios from "axios";

export default {
  data() {
    const validateRulesObj = {
      //自定义校验
      phone: {
        rules: [
          { required: true, message: "请输入手机号码" },
          {
            validator: (rule, value, cbfn) => {
              if (!value) cbfn();
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                cbfn("请输入正确的手机号码");
              }
              cbfn();
            },
          },
        ],
      },
    };
    return {
      show: false,
      validateRulesObj,
      form: this.$form.createForm(this, { name: "coordinated" }),
      products,
      loading: false,
      messageType: 0,
    };
  },
  methods: {
    cancelModal() {
      this.form.resetFields();
      this.show = false;
      this.messageType = 0;
    },
    handleSubmit(e) {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          // 调用接口发送数据
          values.messageType = this.messageType;
          const html_message = this.createTemp(values);
          axios({
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "X-Token":'2b60d59ce6faf6c4bc82752e61055a487dc53300'
            },
            method: "post",
            url: "https://www.bzpgj.com/api/sendEmail/",
            data: {
              subject: "1对1咨询",
	            message: "",
              html_message,
              // recipient: ["24189594@qq.com"],
            },
          }).finally(()=>{
            this.loading = false;
            this.cancelModal();
            message.success("已收到您的留言,请等候人工联系");
            console.log("Received values of form: ", values);
          })
        }
      });
    },
    createTemp(value) {
      switch (value.messageType) {
        case 0:
          value.messageType = "需求描述";
          break;
        case 1:
          value.messageType = "产品建议";
          break;
        case 2:
          value.messageType = "其他";
          break;
      }
      return `
              <div style='width:100%;height: 100%;position: relative;'>
        <div
            style='position: absolute;left: 50%;margin-left: -264px;width:528px;padding: 40px 48px;background: #F8F9FD;border-radius: 16px;'>
            <div>
                <h1
                    style='height: 32px;font-weight: bold;font-size: 24px;line-height: 32px;color: #1f2431;text-align: left;'>
                    客户留言</h1>
                <p
                    style='margin-top: 0;height: 24px;font-weight: normal;font-size: 16px;line-height: 24px;color: #8D9197;text-align: left;'>
                    请在24小时内联系留言客户</p>
            </div>
            <div style='margin-top: 40px;'>
                <p style='display: flex;flex-direction: column;'>
                    <span
                        style='letter-spacing: 1px;width: 88px;font-weight: normal;font-size: 15px;line-height: 22px;color: #8D9197;display: flex;align-items: center;'>联系电话：</span>
                    <span style='color: #117DFF;font-size: 48px;font-weight: bold;line-height: 60px;'>${value.phone.slice(0,3)} ${value.phone.slice(3,7)} ${value.phone.slice(7,11)}</span>
                </p>
                <p style='display: flex;height: 32px;'>
                    <span
                        style='letter-spacing: 1px;width: 88px;font-weight: normal;font-size: 15px;line-height: 22px;color: #8D9197;display: flex;align-items: center;'>客户姓名：</span>
                    <span
                        style='flex: 1;background: #F8F9FD;border-radius: 4px;display: flex;align-items: center;padding: 5px 12px;box-sizing: border-box;font-size: 15px;'>${value.username}</span>
                </p>
                <p style='display: flex;height: 32px;'>
                    <span
                        style='letter-spacing: 1px;width: 88px;font-weight: normal;line-height: 22px;color: #8D9197;display: flex;align-items: center;font-size: 15px;'>咨询产品：</span>
                    <span
                        style='flex: 1;background: #F8F9FD;border-radius: 4px;display: flex;align-items: center;padding: 5px 12px;box-sizing: border-box;font-size: 15px;'>${value.productType}</span>
                </p>
                <div style='display: flex;'>
                    <span
                        style='letter-spacing: 1px;width: 88px;font-weight: normal;line-height: 22px;color: #8D9197;display: flex;align-items: flex-start;font-size: 15px;margin-top: 4px;'>客户留言：</span>
                    <div style='flex: 1;'>
                        <div style='display: flex;height: 32px;align-items: center;padding: 0 12px;'>
                            <span
                                style='position: relative;width: 16px;height: 16px;background: #117DFF;border-radius: 50%;'>
                                <span
                                    style='position: absolute;background-color: #fff;top: 50%;left: 50%;width: 6px;height: 6px;margin-left: -3px;margin-top: -3px;border-radius: 50%;'></span>
                            </span>
                            <span
                                style='margin:2px 0 0 6px;display: flex;align-items: center;font-size: 14px;line-height: 22px;color: #1F2431;'>${value.messageType}</span>
                        </div>
                        <p
                            style='display: flex;flex: 1;padding: 5px 12px;background: #F8F9FD; border-radius: 4px;box-sizing: border-box;font-size: 14px;line-height: 22px;color: #1F2431;min-height:120px;'>
                            ${value.context}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
    },
  },
};
</script>

<style lang="scss">
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #edf0f3 !important;
  border-radius: 4px;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .ant-popover-arrow {
    display: none;
  }
  .scan {
    .ant-popover {
      top: 0 !important;
      left: -370px !important;
      .ant-popover-inner {
        padding: 0 !important;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 8px;
        gap: 24px;
        width: 360px;
        height: 234px;
        background: #ffffff;
        border: 1px solid #edf0f3 !important;
        box-shadow: 0px 4px 32px rgba(17, 125, 255, 0.05);
        .small-box {
          box-sizing: border-box;
          align-items: center;
          padding: 8px;
          gap: 4px;
          width: 160px;
          height: 218px;
          background: #ffffff;
          .small-title {
            height: 24px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #1f2431;
          }
          .small-text {
            margin-top: 0;
            width: 98px;
            height: 22px;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #1f2431;
          }
          .small-img {
            width: 144px;
            height: 144px;
          }
        }
      }
    }
  }
  .telephone {
    .ant-popover {
      top: 70px !important;
      left: -159px !important;
      .ant-popover-inner {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: 148px;
        height: 88px;
        background: #ffffff;
        border: 1px solid #edf0f3;
        box-shadow: 0px 4px 32px rgba(17, 125, 255, 0.05);
        border-radius: 8px;
        .tel-small-box {
          width: 148px;
          height: 88px;
          .tel-name {
            font-weight: normal;
            color: #1f2431;
          }
          .tel-num {
            margin-top: 10px;
            height: 24px;
            font-weight: bold;
            color: #117dff;
          }
          p {
            text-align: center;
            line-height: 24px;
            width: 100%;
            font-size: 16px;
          }
        }
        .ant-popover-inner-content {
          padding: 0 !important;
        }
      }
      .ant-popover-arrow {
        display: none;
      }
    }
    .ant-popover-placement-left,
    .ant-popover-placement-leftTop,
    .ant-popover-placement-leftBottom {
      padding: 0 !important;
    }
  }

  .modal {
    .ant-modal-content {
      border-radius: 16px;
      width: 528px !important;
      height: 592px !important;
      padding: 48px 64px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-modal-body {
        width: 100%;
        height: 100%;
        padding: 0 !important;
        .modal-top {
          p {
            margin-top: 5px;
            height: 24px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #5b5e62;
            text-align: center;
          }
          h1 {
            height: 32px;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #1f2431;
            text-align: center;
          }
        }
        .modal-main {
          margin-top: 30px;
          .ant-form-item {
            display: flex;
          }
          .ant-form-item-label > label::after {
            display: none;
          }
          .ant-form-item-control-wrapper {
            flex: 1;
            margin-left: 20px;
          }
        }
      }
    }
  }
  .ant-select-selection,
  .ant-input {
    background: #f8f9fd !important;
    border: none !important;
  }
}

.toolbox {
  position: fixed;
  right: 16px;
  top: 75%;
  transform: translateY(-50%);
  width: 48px;
  box-shadow: 0 0px 20px 0 rgb(0 20 77 / 14%);
  z-index: 9999;
  border-radius: 10px;
  background: #117dff;
  .main {
    border-radius: 10px;
    background: #117dff;
    align-items: flex-start;
    padding: 0px;
    width: 48px;
    height: 256px;
    filter: drop-shadow(0px 4px 24px rgba(17, 125, 255, 0.3));
    .toolbox-item {
      width: 100%;
      padding: 12px;
      box-sizing: border-box;
      cursor: pointer;
      p {
        margin-top: 4px;
        width: 24px;
        height: 20px;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
      }
      &:hover {
        background: #4197ff;
      }
      img{
        width: 24px;
        height: 24px;
      }
    }
  }
  .ant-back-top {
    bottom: 0%;
    right: 8px;
    transform: translateY(150%);
    .custom-up {
      width: 48px;
      height: 48px;
      background: #117dff;
      box-shadow: 0px 4px 24px rgba(17, 125, 255, 0.3);
      border-radius: 8px;
      img{
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
