<template>
  <div class="big-box">
    <MBHeaderNav />
      <router-view />
    <MBFooterInfo v-if="showFooter" />
  </div>
</template>

<script>
import MBHeaderNav from "@/components/MBHeaderNav.vue";
import MBFooterInfo from "@/components/MBFooterInfo.vue";

export default {
  components: {
    MBHeaderNav,
    MBFooterInfo,
  },
  data() {
    return {
      showFooter:true
    };
  },
  watch: {
    $route: {
      handler(route) {
        if(route.name == 'MBService'){
          this.showFooter = false
        }else{
          this.showFooter = true
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.big-box {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
