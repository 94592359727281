import Vue from 'vue'
import App from './App.vue'
import router from './router'

import "./permission";
import '@/assets/styles/reset.css'

import { Button,Affix,Carousel,Icon,List,Card,Tabs,Layout,Modal,Popover,BackTop,Form,Input,Select,Radio,Drawer,Collapse,PageHeader } from 'ant-design-vue';
 
Vue.component(Button.name, Button)
Vue.component(Affix.name, Affix)
Vue.component(Carousel.name, Carousel)
Vue.component(Icon.name, Icon)
Vue.use(List)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(Layout)
Vue.use(Modal)
Vue.use(Popover)
Vue.use(BackTop)
Vue.use(Form)
Vue.use(Input)
Vue.use(Select)
Vue.use(Radio)
Vue.use(Drawer)
Vue.use(Collapse)
Vue.use(PageHeader)

Vue.config.productionTip = false

new Vue({
  beforeCreate(){
    Vue.prototype.$bus=this
  },
  router,
  render: h => h(App),
}).$mount('#app')
