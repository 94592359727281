import Vue from 'vue'
import VueRouter from 'vue-router'
import PCContainer from "@/components/PCContainer.vue";
import MBContainer from "@/components/MBContainer.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: PCContainer,
    redirect: "/",
    children: [
      {
        path: "/",
        name: 'PCHome',
        component: (resolve) => require(["@/views/PCHome.vue"], resolve)
      },
      {
        path: '/service',
        name: 'PCService',
        component: (resolve) => require(["@/views/PCService.vue"], resolve)
      },
      {
        path: '/about',
        name: 'PCAbout',
        component: (resolve) => require(["@/views/PCAbout.vue"], resolve)
      }
    ]
  },
  {
    path: '/mobile',
    component: MBContainer,
    redirect: "/mobile",
    children: [
      {
        path: "/mobile",
        name: 'MBHome',
        component: (resolve) => require(["@/views/MBHome.vue"], resolve)
      },
      {
        path: '/mobile/service',
        name: 'MBService',
        component: (resolve) => require(["@/views/MBService.vue"], resolve)
      },
      {
        path: '/mobile/about',
        name: 'MBAbout',
        component: (resolve) => require(["@/views/MBAbout.vue"], resolve)
      },
    ]
  },
  {
    path: '/mobile/message',
    name: 'MBMessage',
    component: (resolve) => require(["@/views/MBMessage.vue"], resolve)
  },
  {
    path: "/mobile/404",
    component: (resolve) => require(["@/views/MB404.vue"], resolve),
  },
  {
    path: "/404",
    component: (resolve) => require(["@/views/404.vue"], resolve),
  },
  {     
    path: '*',
    redirect: '/404', 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
